<template>
  <v-app-bar
    id="navbar"
    flat
    color="darknavyblue"
    class="text-white"
    height="60"
    :extended="!!routeMeta.isTabsList"
    extension-height="55"
  >
    <template v-if="routeMeta.isTabsList" #extension>
      <NavbarHeader />
    </template>
    <v-badge
      dot
      :model-value="!!totalUnreadReleaseNotes"
      color="error"
    >
      <v-btn
        v-if="onBack"
        id="navbar--back"
        key="v-btn-1"
        class="text-white"
        icon
        size="large"
        @click="goBack"
      >
        <v-icon icon="mdi-arrow-left" />
      </v-btn>
      <v-btn
        v-else-if="!hideSidebar"
        id="navbar--sidebar-toggle"
        key="v-btn-2"
        class="text-white"
        icon
        size="large"
        @click.prevent="toggleSidebar"
      >
        <v-icon icon="mdi-menu" />
      </v-btn>
    </v-badge>
    <v-btn
      v-if="showHome"
      theme="dark"
      icon
      class="ml-2"
      @click="goHome"
    >
      <v-icon icon="mdi-home" />
    </v-btn>
    <v-toolbar-title class="!fs-24 mx-2">
      <span>{{ title }}</span>
      <span v-if="isBetaEnv">(Beta)</span>
      <v-chip
        v-if="showChip"
        class="v-chip--size-xs ml-4 pl-4 pr-3 !font-bold text-uppercase"
        size="small"
        v-bind="chipProps"
      />
    </v-toolbar-title>
    <v-toolbar-items>
      <ProgramSwitcher v-if="showSwitcher" />
    </v-toolbar-items>
  </v-app-bar>
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'

import NavbarHeader from '@/components/common/NavbarHeader/NavbarHeader.vue'
import ProgramSwitcher from '@/components/common/ProgramSwitcher/ProgramSwitcher.vue'
import { useNotification } from '@/composables/notification'
import { usePermission } from '@/composables/permission'
import { useApiStore } from '@/stores/api'
import { useAppContextStore } from '@/stores/appContext'
import { useApplicationStore } from '@/stores/application'
import { useAuthenticationStore } from '@/stores/authentication'
import { useCmsStore } from '@/stores/cms'
import { useDocumentStore } from '@/stores/document'
import { useFormEditStore } from '@/stores/form/edit'
import { useGlobalStore } from '@/stores/global'

import { FORM_NAVIGATION_WARNING } from '@/constants'
import routeNames from '@/router/routeNames'

import type { AgencyPermission } from '@/composables/permission'

export default defineComponent({
  name: 'Navbar',

  components: {
    ProgramSwitcher,
    NavbarHeader,
  },

  emits: {
    toggle: (): true => true,
  },

  props: {
    hideSidebar: { type: Boolean, default: false },
    onBack: { type: String, default: undefined },
  },

  setup (props) {
    const { checkPermission } = usePermission<AgencyPermission>()
    const { userConfirm } = useNotification()

    const applicationStore = useApplicationStore()
    const PNI = computed(() => applicationStore.PNI)

    const apiStore = useApiStore()
    const showSwitcher = computed(() => {
      return !props.hideSidebar && !!apiStore.spaToken
    })
    const showChip = computed(() => applicationStore.ready && routeMeta.value.applicationLayout)

    const route = useRoute()
    const routeMeta = computed(() => route.meta ?? {})

    return {
      checkPermission,
      userConfirm,

      PNI,

      showSwitcher,
      showChip,

      routeMeta,
    }
  },

  computed: {
    ...mapState(useCmsStore, {
      totalUnreadReleaseNotes: 'countSinceLastRead',
    }),
    ...mapState(useAuthenticationStore, {
      currentProgram: 'currentProgram',
    }),
    ...mapState(useGlobalStore, {
      isBetaEnv: 'isBetaEnv',
    }),
    ...mapStores(
      useDocumentStore,
      useFormEditStore,
      useAppContextStore,
    ),
    showHome (): boolean {
      return this.$route.name !== routeNames.HOME &&
        this.$route.name !== routeNames.PRIVACY &&
        this.$route.name !== routeNames.TERMS
    },
    title (): string {
      const { matched, name } = this.$route
      if (matched.some((r) => r.meta.applicationLayout)) return this.PNI
      if (matched.some((r) => r.meta.accountLayout)) {
        return 'My Account'
      }
      switch (name) {
        case routeNames.HOME:
        case routeNames.TASKS:
          return 'Home'
        case routeNames.RELEASE_NOTES:
          return 'Program Alerts'
        case routeNames.RATING:
          return 'Rating'
        case routeNames.REPORTING:
          return 'Reporting'
        case routeNames.USER_USERSLIST:
          return 'Internal Users'
        case routeNames.USER_CREATE:
          return 'User Management'
        case routeNames.DOCUMENT_DOCUMENTSLIST:
        case routeNames.DOCUMENT_NEW:
          return 'Document Manager'
        case routeNames.DOCUMENT_REVISIONSLIST:
        case routeNames.DOCUMENT_REVISION_NEW:
        case routeNames.DOCUMENT_REVISION:
          return (this.documentStore.loadedDocument || {}).verbose_name || 'Revisions'
        case routeNames.FORGOT_PASSWORD_RESET_PASSWORD:
          return 'Reset Password'
        case routeNames.FORGOT_PASSWORD:
          return 'Forgot Password'
        case routeNames.AGENCY_LIST:
          return 'Agencies'
        case routeNames.AGENCY_DETAIL:
          // assuming that someone cannot view my agency & all agencies too
          if (this.checkPermission('myAgencyView')) {
            return 'My Agency'
          } else {
            return 'Agencies'
          }
        default:
          return ''
      }
    },
    chipProps (): Record<string, string> {
      switch (true) {
        case this.routeMeta.policyView:
          return {
            text: 'Policy',
            prependIcon: 'mdi-briefcase',
            color: 'info-darken-2',
            class: 'bg-info-lighten-2',
          }
        case this.appContextStore.isCancellation:
          return {
            text: 'Cancellation',
            prependIcon: 'mdi-file-document-remove',
            color: 'warning-darken-2',
            class: 'bg-warning-lighten-1',
          }
        default: {
          let text: string = this.appContextStore.kind
          let prependIcon = 'mdi-file-document-multiple'
          if (this.appContextStore.isReinstatement) {
            text = 'Reinstatement'
            prependIcon = 'mdi-file-document-arrow-right'
          } else if (this.appContextStore.kind === 'APPLICATION') {
            text = 'New Application'
            prependIcon = 'mdi-file-document'
          }
          return {
            text,
            prependIcon,
            color: 'success-darken-2',
            class: 'bg-success-lighten-1',
          }
        }
      }
    },
  },

  methods: {
    goBack (): void {
      if (!this.onBack) return
      if (this.onBack === '-1') this.$router.back()
      else this.$router.push({ name: this.onBack })
    },
    async goHome (): Promise<void> {
      if (this.$route.meta?.form && !await this.userConfirm({ title: FORM_NAVIGATION_WARNING })) return
      this.$router.push({ name: routeNames.HOME })
    },
    toggleSidebar (): void {
      this.$emit('toggle')
    },
  },
})
</script>

<style lang="sass" scoped>
#navbar
  :deep(.v-toolbar__extension)
    padding: 0
  // @vuetify-override
  :deep(.v-badge__badge)
    bottom: calc(100% - 12px) !important
</style>
