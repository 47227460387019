import { RpcError } from '@protobuf-ts/runtime-rpc'
import { isCancel, isAxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'

import type { AnyObject } from '@policyfly/types/common'
import type { JwtPayload } from 'jwt-decode'

/**
 * Converts a JS object to a {@link FormData} representation.
 */
export function objectToFormData (obj: AnyObject, formData = new FormData()): FormData {
  for (const [k, v] of Object.entries(obj)) {
    if (v instanceof Blob) {
      formData.append(k, v)
    } else if (typeof v === 'object' || Array.isArray(v)) {
      formData.append(k, JSON.stringify(v))
    } else {
      formData.append(k, v)
    }
  }
  return formData
}

/**
 * Converts a base64 string (without prefix) to a {@link Uint8Array}.
 *
 * @example
 * const base64str = Application.policy_state
 * const uintArr = base64ToUint8Array(base64str)
 * const policyState = PolicyState.fromBinary(uintArr)
 */
export function base64ToUint8Array (base64: string): Uint8Array {
  return Uint8Array.from(window.atob(base64), (v) => v.charCodeAt(0))
}

/**
 * Converts bytes to a data url that can be displayed in the browser or downloaded.
 * A mimeType can also be provided.
 */
export function bytesToDataURL (bytes: string | Uint8Array, mimeType?: string): string {
  const blob = new Blob([bytes], { type: mimeType })
  return URL.createObjectURL(blob)
}

/**
 * Converts a {@link Blob} to a base64 string (without prefix).
 *
 * @example
 * const uintArr = PolicyState.toBinary(policyState)
 * const blob = new Blob([uintArr])
 * const base64str = await blobToBase64(blob)
 */
export function blobToBase64 (blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const result = reader.result as string
      const base64 = result.substring(result.indexOf(',') + 1)
      resolve(base64)
    }
    reader.onerror = (err) => reject(err)
    reader.readAsDataURL(blob)
  })
}

/**
 * Handles the cancellation promise rejection that is thrown when a request is cancelled.
 * If any other error is thrown then it can be handled in a following `catch` block.
 *
 * @example
 * const promise = api.path.method()
 * promise
 *   .then(...)
 *   .catch(cancelledRequestHandler)
 *   // optional handling for any non-cancel errors
 *   .catch((err) => {})
 *
 * // when this is called the cancellation error will be ignored
 * promise.abort()
 */
export function cancelledRequestHandler (err: unknown): void {
  if (isCancel(err)) return
  throw err
}

/**
 * Checks if the provided error is an AbortError.
 * This is thrown when an AbortController is used to cancel a request.
 * Handles both normal fetch and GRPC errors.
 */
export function isAbortError (error: unknown): boolean {
  return (error instanceof DOMException && error.name === 'AbortError') ||
  (error instanceof RpcError && error.code === 'CANCELLED')
}

/**
 * Returns `true` if a JWT is valid.
 *
 * Returns `false` if the provided JWT is badly formatted
 * or the `exp` timestamp is in the past (with a small buffer).
 */
export function isJWTValid (jwt: string | null): jwt is string {
  if (!jwt) return false
  try {
    const decoded = jwtDecode<JwtPayload>(jwt)
    if (!decoded.exp) return false
    return Date.now() < ((decoded.exp * 1000) - 10000)
  } catch {
    return false
  }
}

/**
 * Retrieve an error message from a Django or gRPC api call failure.
 *
 * Falls back to provided string if no error string can be extracted.
 * Returns an empty string if no fallback is provided, and no error can be extracted.
 */
export function extractApiError (error: unknown, fallback?: string): string {
  switch (true) {
    case isAxiosError(error): {
      // extract the first error from either the data or non_field_errors
      if (Array.isArray(error.response?.data)) {
        return error.response?.data[0]
      } else if (Array.isArray(error.response?.data?.non_field_errors)) {
        return error.response.data.non_field_errors[0]
      } else {
        return fallback ?? ''
      }
    }
    case error instanceof RpcError:
      return decodeURIComponent(error.message) ?? fallback
    default:
      return fallback ?? ''
  }
}
