<template>
  <v-app :theme="theme">
    <Sidebar ref="sidebarRef" />
    <Navbar
      v-if="$route && !routeMeta.hideNavbar"
      :hide-sidebar="routeMeta.hideSidebar"
      :on-back="routeMeta.onBack"
      @toggle="onToggle"
    />
    <v-main class="h-screen">
      <router-view />
    </v-main>
    <Snackbar />
    <Dialog />
    <TelemetryDialog v-model="isRequestingSupport" />
  </v-app>
</template>

<script lang="ts">
import { useColorMode, useWindowFocus } from '@vueuse/core'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useUserStore } from './stores/user'
import { useSupport } from '@/composables/support'
import { useVersion } from '@/composables/version'
import { useApiStore } from '@/stores/api'
import { useGlobalStore } from '@/stores/global'
import Navbar from 'components/common/Navbar/Navbar.vue'
import Sidebar from 'components/common/Sidebar/Sidebar.vue'
import Dialog from 'components/global/Notification/Dialog.vue'
import Snackbar from 'components/global/Notification/Snackbar.vue'
import TelemetryDialog from 'components/global/Notification/TelemetryDialog.vue'

import type { StrictRouteConfig } from 'types/router'

export default defineComponent({
  name: 'App',

  components: {
    Navbar,
    Sidebar,
    Snackbar,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    TelemetryDialog,
  },

  setup () {
    const apiStore = useApiStore()
    const userStore = useUserStore()
    if (apiStore.spaToken) {
      userStore.load()
    }

    const sidebarRef = ref<{ toggle(): void } | null>(null)
    function onToggle (): void {
      sidebarRef.value?.toggle()
    }

    const { isRequestingSupport } = useSupport()

    const route = useRoute()
    const routeMeta = computed<NonNullable<StrictRouteConfig['meta']>>(() => route.meta ?? {})

    const globalStore = useGlobalStore()
    const mode = useColorMode()
    const theme = computed(() => {
      if (globalStore.isBetaEnv) return 'pink'
      if (import.meta.env.VITE_THEME_SUPPORT !== 'true') return 'light'
      return mode.value
    })

    const focused = useWindowFocus()
    const { checkSpaVersion } = useVersion()
    onMounted(async () => {
      while (true) {
        if (focused.value) {
          try {
            const { version } = await apiStore.version.check()
            await checkSpaVersion(version)
          } catch {}
        }
        await new Promise((resolve) => window.setTimeout(resolve, 5000 * 60))
      }
    })

    return {
      sidebarRef,
      onToggle,

      isRequestingSupport,

      routeMeta,

      theme,
    }
  },
})
</script>

<!-- eslint-disable-next-line vue/enforce-style-attribute -->
<style lang="sass">
.no-top-border-radius
  border-radius: 0px 0px 4px 4px

.program-menu
  top: 8px !important
.attachments-menu .v-menu--inline
  display: none

.dense-input
  z-index: 4
  height: 42px
  input
    padding: 4px 0 2px 0
    line-height: 1em
  .v-input__control
    height: 42px
    .v-input__slot
      margin-bottom: 4px
      min-height: 42px
      max-height: 42px
      fieldset
        max-height: 42px
        border: 2px white solid
      .v-select__slot
        height: 42px
        .v-select__selections
          padding: 0
          height: 42px
          margin-bottom: 6px
          .v-select__selection
            font-size: 13px
        .v-input__append-inner
          margin-top: 6px
</style>
