import { ProvisionDeviceRequest } from '@policyfly/protobuf'
import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { api } from '@/api'
import { hotReloadStore } from '@/utils/build'

import type { ProvisionDeviceResponse_Totp } from '@policyfly/protobuf'

export const useOnboardingStore = defineStore({
  id: 'onboarding',

  state: () => ({
    id: 0,
    email: '',
    otp_token: '',
    password: '',
    number: '',
    first_name: '',
  }),

  getters: {
    loaded: (state) => !!state.first_name,
  },

  actions: {
    async loadUser ({ email, otp_token: otpToken }: { email: string, otp_token: string }): Promise<void> {
      const res = await api.users.fetch({ body: { email, otp_token: otpToken } })
      if (!res.data.User) throw new Error('User not found in response')
      if (res.data.User.is_active) throw new Error('User already exists')
      this.id = res.data.User.id
      this.first_name = res.data.User.first_name
      this.email = res.data.User.email
      this.otp_token = otpToken
    },
    async setupAccount (payload: { password: string }): Promise<void> {
      const apiStore = useApiStore()
      const { id } = await apiStore.user.setupAccount({
        password: payload.password,
        email: this.email,
        token: this.otp_token,
      })
      this.otp_token = ''
      const authenticationStore = useAuthenticationStore()
      authenticationStore.userId = id
    },
    async provisionSMS (newNumber: string): Promise<void> {
      const apiStore = useApiStore()
      if (newNumber) this.number = newNumber
      const request = ProvisionDeviceRequest.create({
        emailAddress: this.email,
        method: { oneofKind: 'phone', phone: { phoneNumber: this.number } },
      })
      await apiStore.user.provisionDevice(request)
    },
    async provisionQR (): Promise<ProvisionDeviceResponse_Totp> {
      const apiStore = useApiStore()
      const request = ProvisionDeviceRequest.create({
        emailAddress: this.email,
        method: { oneofKind: 'totp', totp: {} },
      })
      const response = await apiStore.user.provisionDevice(request)
      if (response.method.oneofKind !== 'totp') {
        throw new Error('Unexpected response from server')
      }
      return response.method.totp
    },
    async provisionEmail (): Promise<void> {
      const apiStore = useApiStore()
      const request = ProvisionDeviceRequest.create({
        emailAddress: this.email,
        method: { oneofKind: 'email', email: {} },
      })
      await apiStore.user.provisionDevice(request)
    },
  },
})

hotReloadStore(useOnboardingStore)
